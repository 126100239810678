<template>
  <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" :size="subUnitSize">
        <el-form-item label="下单分类" prop="order_cate_arr">
          <el-cascader
            v-model="table.params.order_cate_arr"
            filterable placeholder="请选择"
            id="order_cate_arr"
            @change="changeOrderCateId"
            @focus="getOrderCateSel"
            :options="orderCateArr"
            :props="{ children: 'child',label: 'name',value:'id', checkStrictly: true }"
            clearable></el-cascader>
        </el-form-item>
        <el-form-item label="标准名称" prop="goods_name">
          <el-input v-model="table.params.goods_name" @keydown.enter.native="onSearch" placeholder="请输入标准名称" clearable></el-input>
        </el-form-item>
        <el-form-item label="仓库查询" prop="stash_id">
          <el-select v-model="table.params.stash_id" filterable placeholder="请输入" @focus="getStash" clearable @change="onSearch">
            <el-option
              v-for="item in stashArr"
              :key="item.id"
              :label="item.name"
              :value="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.province_str }}{{item.city_str}}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <!-- 超级管理员才有地区查询条件 -->
        <el-form-item label="地区" prop="area" v-if="roleType == 1">
          <el-cascader
            v-model="table.params.area"
            :options="areaArr"
            :props="{ checkStrictly: true }"
            clearable
            filterable
            style="width:100%;"
            @change="onSearch">
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" icon="el-icon-search" @click="onSearch">搜索</el-button>
          <el-button icon="el-icon-refresh-right" @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <div class="btnAdd">
        <el-button :size="subUnitSize" type="primary" @click="exportDataAll('/admin/inventory/export')" icon="el-icon-download" >全部导出</el-button>
        <el-button :size="subUnitSize" type="primary" plain @click="plexportData('/admin/inventory/export')">批量导出</el-button>
        <el-checkbox v-model="is_zero"  size="mini" style="margin-left:10px" @change="onSearch">过滤0库存</el-checkbox>
      </div>
    </div>
    
    <VTable
      has-pagionation
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showSelect="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    > 
      <template v-slot:quantity="{row}">
        <span>{{row.quantity}}</span>
      </template>
      <template v-slot:spec_names="{row}">
        <span v-if="row.spec_names.length > 1">存在多个商品规格</span>
        <span v-else>{{row.spec_names[0]}}</span>
      </template>
      <!-- 现有库存 = (入库数量 - 已出库 - 已处理) -->
      <template v-slot:text="{row}">
        <span>{{(Number(row.quantity) - Number(row.out_quantity) - Number(row.env_quantity)).toFixed(2) * 1}}</span>
      </template>
      <template v-slot:cate_name="{row}">
        <span>{{row.cate_name}}</span>
        <span v-if="!!row.order_name_tow"> / {{row.order_name_tow}}</span>
      </template>
      <template v-slot:action="{row}">
        <el-button type="text" @click="showDetail(row)">查看明细</el-button>
        <el-button type="text" @click="showExcise(row)">出库</el-button>
        <!-- is_change: 1:能转换 -->
        <el-button v-if="row.is_change == 1" type="text" @click="showUnitcut(row)">单位转换</el-button>
      </template>
    </VTable>

    <!-- 查看明细 -->
    <detail ref="detail"></detail>
    <!-- 出库 -->
    <Excise ref="excise"  @refresh="getTable"></Excise>
    <!-- 单位转换 -->
    <Unitcut ref="unitcut"  @refresh="getTable"></Unitcut>

  </div>
</template>

<script>
import Detail from './components/Detail.vue'
import Excise from './components/Excise.vue'
import Unitcut from './components/Unitcut.vue'
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'
import { getStorage } from '@/storage'

export default {
  name: 'storeStorckDetail',
  components: {
    Detail,
    Excise,
    Unitcut,
    VTable,
  },
  mixins: [mixinTable],
  computed: {
    ...mapGetters(['goodsTypeArr','areaArr'])
  },
  data() {
    return {
      roleType: getStorage('role_type'),// 1:超级管理员，2:管理员, 3：员工
      subUnitSize: this.$store.state.subUnitSize, // 获取组件 size属性
      multipleSelection: [],
      is_zero: false, // 是否过滤0 1:是 0:否
      field: [
        { name: "norm_name", label: "标准名称", width: "180",showTooltip: true, hidden: false},
        { name: "cate_name", label: "下单分类", width:'120', hidden: false},
        { name: "unit_name", label: "单位",  hidden: false},
        { name: "spec_names", label: "规格",  hidden: false},
        { name: "quantity", label: "入库数量",  hidden: false},
        { name: "out_quantity", label: "已出库", hidden: false},
        { name: "env_quantity", label: "已报损", hidden: false},
        { name: "text", label: "现有库存", showTooltip: true, hidden: false},
        { name: "inventory_time", label: "更新时间", hidden: false},
        { name: "action", label: "操作",width: "190", fixed:"right", hidden: false }
      ],
      table: {
        loading: false,
        params: {
          cate_id: '',
          order_cate_arr: [],
          order_cate_id: '', // 下单分类
          order_cate_tow: '', // 二级下单分类
          goods_name: '',
          stash_id: '',
          area:[],
          province: '',
          city: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      goodsArr: [],
      stashArr: [],
      orderCateArr: [],
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    getTable() {
      this.table.params.is_zero = !!this.is_zero ? 1 : 0;
      let _params = {...this.table.params}
      let _area = this.table.params.area;
      if(_area.length == 1) {
        _params.province = _area[0]
      } else if(_area.length == 2) {
        _params.province = _area[0]
        _params.city = _area[1]
      } else {
        _params.province = '';
        _params.city = '';
      }
      _params.cate_id = this.table.params.order_cate_id;
      delete _params.order_cate_id;
      delete _params.order_cate_arr;
      delete _params.area;
      delete _params.order_cate_arr;
      this.table.loading = true;
      this.$http.get('/admin/inventory/list', {params:_params}).then(res => {
        if(res.code === 1) {
          this.table.loading = false;
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        }
      })
    },
    // 重置查询条件
    reset() {
      this.$refs.elFormDom.resetFields()
      this.table.params.page = 1;
      this.table.params.count = 10;
      this.table.params.order_cate_arr = [];
      this.table.params.order_cate_id = '';
      this.table.params.order_cate_tow = '';
      this.is_zero = '';
      this.getTable();
    },
    // 查看明细
    showDetail(row){
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 出库
    showExcise(row) {
      let dom = this.$refs.excise;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 单位转换
    showUnitcut(row) {
      let dom = this.$refs.unitcut;
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 获取仓库
    getStash() {
      this.$http.get('/admin/stash/list', { params:{ page: 1, count: 10000}}).then(res => {
        if(res.code == 1) {
          this.stashArr = res.data.list;
        }
      })
    },
    // 批量导出
    plexportData(url) {
      if (this.multipleSelection.length) {
        let ids = this.multipleSelection
          .map((item) => {
              return item.ids;
          }).join("|");
        let str = ""
        let obj = {
          ...this.table.params,
          ids
        }
        console.log(ids)
        Object.keys(obj).forEach(item => {
            str += `&${item}=${obj[item]}`
        })
        window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      } else {
        this.$message.warning("至少勾选一条数据");
      }
    },
    // 全部导出
    exportDataAll(url) {
      let str = ""
      this.table.params.is_zero = !!this.is_zero ? 1 : 0;
      let obj = {...this.table.params}
      let _area = this.table.params.area;
      if(_area.length == 1) {
        obj.province = _area[0]
      } else if(_area.length == 2) {
        obj.province = _area[0]
        obj.city = _area[1]
      } else {
        obj.province = '';
        obj.city = '';
      }
      obj.cate_id = this.table.params.order_cate_id;
      delete obj.area;
      delete obj.order_cate_arr;
      delete obj.order_cate_id;
      Object.keys(obj).forEach(item => {
        str += `&${item}=${obj[item]}`
      })
      console.log(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
      window.open(`${process.env.VUE_APP_BASE_API}${url}?token=${this.token}${str}`)
    },
    
  }
}
</script>
